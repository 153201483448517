@charset "UTF-8";
/*CSS重置"PingFang SC",*/
body,
html {
  margin: 0;
  padding: 0;
  font-family: "PingFang SC","Microsoft YaHei",arial,"\5b8b\4f53",sans-serif,tahoma;
  color: #333;
  background: #fff; }

input,
textarea {
  font-family: "PingFang SC","Microsoft YaHei",arial,"\5b8b\4f53",sans-serif,tahoma; }

body {
  font-size: 14px; }
  body a {
    outline: none;
    text-decoration: none !important; }
    body a:hover {
      color: #4f91ff !important; }

blockquote,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

li {
  list-style: none; }

img {
  border: none; }

em,
i {
  font-style: normal; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.clearfix:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: ''; }

* html .clearfix {
  height: 1%; }

* + html .clearfix {
  height: 1%; }

.clearfix {
  display: block;
  clear: both; }

*:focus {
  outline: none; }

input {
  outline: none; }

* {
  box-sizing: border-box; }

input[type="button"],
input[type="number"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: none; }

textarea {
  -webkit-appearance: none;
  resize: none; }

.fl {
  float: left; }

.fr {
  float: right; }

.container {
  width: 1205px;
  margin: 0 auto; }

img {
  max-width: 100%; }
